import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <img src="1888.jpg" alt="el toto tomando sidra" />
    </div>
  );
}

export default App;
